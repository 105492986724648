/**
 * Game Photo Gallery Page
 * 
 * Shows all of the photos submitted by all of the teams, given a specific gallery code.
 */

import React from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import PhotoGallery from '../components/photos/photo-gallery'

export default class GalleryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { gallery_code: null }
  }

  componentDidMount() {
    let query = location.search ? queryString.parse(location.search) : {}
    if (query.code !== undefined && query.code !== ``) {
      this.setState({ gallery_code: query.code })
    } else {
      navigate(`/`)
    }
  }

  render() {
    return (
      <div className="photo-gallery-wrapper">
        <h1 className="sr-only">Photo Gallery</h1>
        {this.state.gallery_code !== null && <PhotoGallery gallery_code={this.state.gallery_code} />}
      </div>
    )
  }
}
