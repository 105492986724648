/**
 * Photo Gallery Component
 * 
 * Shows all the photos taken by all teams during a hunt.
 * Needs a gallery code to work. Also allows users to expand a photo
 * to look at it closer.
 */
import React from 'react'
import PropTypes from 'prop-types'
import API from '../../utils/api'
import Photo from './photo'
import Modal from 'react-bootstrap/Modal'
import { getMediaUrl } from '../../utils/helpers'

export default class PhotoGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      modalShow: false,
      active_image: {},
    }
    this.setActivePhoto = this.setActivePhoto.bind(this)
    this.modalClose = this.modalClose.bind(this)
  }

  componentDidMount() {
    API.get(`photos/${this.props.gallery_code}/`)
      .then(res => {
        const data = res.data
        this.setState({
          images: data.hunt_photos.concat(data.photos),
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  setActivePhoto(photo) {
    this.setState({
      active_image: photo,
      modalShow: true,
    })
  }

  modalClose() {
    this.setState({
      modalShow: false,
    })
  }

  render() {
    const { images, active_image, modalShow } = this.state
    let select_func = this.setActivePhoto
    if (this.props.onClick) {
      select_func = this.props.onClick
    }
    return (
      <>
        {/* <code>{JSON.stringify(this.state.image_data)}</code> */}
        <div className={`photo-list row no-gutters`}>
          {images !== undefined &&
            images.map((photo, i) => (
              <div key={photo.id} className={`col-4 py-0 px-0 text-center`}>
                <Photo photo={photo} onClick={() => select_func(photo)} />
              </div>
            ))}
        </div>
        <Modal show={modalShow} onHide={this.modalClose} centered className="photo-modal" aria-labelledby="photo-detail-modal-title">
          <h2 id="photo-detail-modal-title" className="sr-only">Photo Details</h2>
          <Modal.Body className={`text-center text-uppercase px-0 py-0`}>
            {active_image.image !== undefined && (
              <>
                <img className="img-fluid" src={getMediaUrl(active_image.image)} />
              </>
            )}
            <button className={`btn btn-block btn-tiny text-uppercase mt-5`} onClick={this.modalClose}>
              Close
            </button>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

PhotoGallery.propTypes = {
  gallery_code: PropTypes.string,
  onClick: PropTypes.func,
}

PhotoGallery.defaultProps = {
  gallery_code: null,
}
